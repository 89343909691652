.cardContainer {
    background: #FFF;
    /* width: 180px;
    height: 120px; */
    padding: 10px 10px;
    border-radius: 5px;
    box-sizing: border-box;
    box-shadow: 2px 2px 1px rgb(218, 218, 218);
}

.iconeModulo {
    width: 20px;
    margin-top: -8px;
}

.topoTitulo {
    font-weight: bold;
    font-size: 18px;
    margin-left: 5px;
}

.cardCorpo {
    margin-top: 8px;
}
