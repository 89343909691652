.pivotChart {
    background: #FFF;
    margin-bottom: 15px;
    margin-top: 5px;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 4px;
    width: 100%;
}

.filtrosData{
    display: flex;
    flex-direction: row;
    column-gap: 5px;
}

.refreshButton{
    cursor: pointer;
}