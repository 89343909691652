.animationLoading {
    display: inline-block;
    height: 80px;
}

.animationLoadingTopo {
    display: inline-flex;
}

.animationLoadingFoto,
.animationLoadingLineA,
.animationLoadingLineB,
.animationLoadingLineC,
.animationLoadingLineD,
.animationLoadingLineE {
    animation-name: detalhesChamadoAnimateLoading;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    background: -webkit-gradient(linear, left top, right top, color-stop(8%, #eeeeee), color-stop(18%, #dddddd), color-stop(33%, #eeeeee));
    background: -webkit-linear-gradient(left, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
    background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
    background-size: 800px 104px;
}

.animationLoadingFoto {
    left: 0;
    min-height: 32px;
    min-width: 32px;
    max-height: 32px;
    max-width: 32px;
}

.animationLoadingLineA {
    left: 50px;
    top: 0px;
    width: 100px;
    height: 10px;
}

.animationLoadingLineB {
    left: 50px;
    top: 50px;
    width: 100px;
    height: 10px;
}

.animationLoadingLineC {
    left: 50px;
    top: 150px;
    width: 200px;
    height: 10px;
}

.animationLoadingLineD {
    left: 50px;
    top: 200px;
    width: 200px;
    height: 10px;
}

.animationLoadingLineE {
    left: 50px;
    top: 250px;
    width: 200px;
    height: 10px;
}

@keyframes detalhesChamadoAnimateLoading {
    0% {
        background-position: -468px 0
    }

    100% {
        background-position: 468px 0
    }

}

.detalhesChamadoStyleSemMargin {
    margin: 0px
}

.detalhesChamadoStyleSemPadding {
    padding: 0px
}

.detalhesChamadoStyleComPadding {
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 0px;
    padding-top: 10px;
}

.hrMensagem {
    margin: 1px;
    padding-bottom: 10px;
    margin-left: 5px;
    margin-right: 5px;
}

.mensagemChamado > img {
    max-width: 100%;
}