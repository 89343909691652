.container {
    padding: 5px;
}

.card {
    background: #FFF;
    height: 120px;
    /* width: 170px; */
    padding: 10px 10px;
    border-radius: 5px;
    box-sizing: border-box;
    box-shadow: 2px 2px 1px rgb(218, 218, 218);
}

.topoTitulo {
    font-weight: bold;
    font-size: 18px;
}

.button {
    float: right;
    cursor: pointer;
    margin-left: 4px;
}

.cardCorpo {
    margin-top: 8px;
    line-height: 10px;
}

.cardCorpo p {
    font-size: 12px;
}

.qtdEmAtraso {
    color: red;
}

.cardRodape {
    padding-top: 10px;
}

.totalSection {
    float: right;
}

.totalIndicator {
    font-weight: bold;
    font-size: 17px;
}