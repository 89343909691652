.container {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.loadingIndicator {
    display: flex; 
    flex-direction: column; 
    gap: 10px; 
    align-items: center;
}
